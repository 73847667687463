

















import { defineComponent } from '@vue/composition-api'

import { fixedSlideProps } from './FixedSlide.hooks'

/**
 * @author Maciej Perzankowski <maciej.perzankowski@movecloser.pl>
 */
export const FixedSlide = defineComponent({
  name: 'FixedSlide',
  props: fixedSlideProps
})

export default FixedSlide
