// Copyright © 2022 Move Closer

import { PropType } from '@vue/composition-api'

import { ComponentObjectPropsOptions } from '../../../vue-api'

import { HeadingProps } from '../../../atoms/Heading'
import { LinkProps } from '../../../atoms/Link'
import { ImageProps } from '../../../atoms/Image'

import { FixedSlideProps } from './FixedSlide.contracts'

/**
 * @author Maciej Perzankowski <maciej.perzankowski@movecloser.pl>
 */
export const fixedSlideProps: ComponentObjectPropsOptions<FixedSlideProps> = {
  /**
   * @see ArtisticCarouselProps.heading
   */
  heading: {
    type: Object as PropType<HeadingProps>,
    required: true
  },

  /**
   * @see ArtisticCarouselProps.link
   */
  link: {
    type: Object as PropType<LinkProps>,
    required: true
  },

  /**
   * @see ArtisticCarouselProps.image
   */
  image: {
    type: Object as PropType<ImageProps>,
    required: true
  }
}
