






import { Component } from 'vue-property-decorator'

import ArtisticCarousel from '../../../dsl/molecules/ArtisticCarousel/ArtisticCarousel.vue'
import { ArtisticCarouselProps } from '../../../dsl/molecules/ArtisticCarousel'

import { AbstractModuleUi } from '../../abstract/ui'

import { ArtisticCarouselModule } from '../ArtisticCarousel.contracts'

import { translateContentToArtisticCarouselProps } from '../ArtisticCarousel.helpers'

/**
 * Container component for the `ArtisticCarouselModuleUi`.
 *
 * @author Maciej Perzankowski <maciej.perzankowski@movecloser.pl>
 */
@Component<ArtisticCarouselModuleUi>({
  name: 'ArtisticCarouselModuleUi',
  components: { ArtisticCarousel }
})
export class ArtisticCarouselModuleUi extends AbstractModuleUi<ArtisticCarouselModule> {
  public get shouldRender (): boolean {
    return typeof this.content.heading !== 'undefined' && this.content.slides.length > 0 &&
      typeof this.content.link !== 'undefined' && typeof this.content.image !== 'undefined'
  }

  public get artisticCarouselProps (): ArtisticCarouselProps {
    return translateContentToArtisticCarouselProps(this.content)
  }
}

export default ArtisticCarouselModuleUi
