































import { defineComponent, getCurrentInstance } from '@vue/composition-api'

import { ArtisticCarouselProps } from './ArtisticCarousel.contracts'
import { artisticCarouselProps, useArtisticCarousel } from './ArtiscticCarousel.hooks'

import FixedSlide from './partials/FixedSlide.vue'

/**
 * @author Maciej Perzankowski <maciej.perzankowski@movecloser.pl>
 */
export const ArtisticCarousel = defineComponent({
  name: 'ArtisticCarousel',
  components: { FixedSlide },
  props: artisticCarouselProps,
  setup (props: ArtisticCarouselProps) {
    return useArtisticCarousel(props, getCurrentInstance())
  }
})

export default ArtisticCarousel
